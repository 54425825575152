import React from 'react'
import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const GridMedium: React.FC<SvgProps> = ({ underline, ...props }) => {
  if (underline) {
    return (
      <Icon viewBox="0 0 16 20" {...props}>
        <rect
          x="9.51922"
          y="0.57"
          width="5.51"
          height="14.06"
          stroke-width="1.14"
        />
        <rect
          x="0.968438"
          y="0.57"
          width="5.51"
          height="14.06"
          stroke-width="1.14"
        />
        <line y1="19.6" x2="16" y2="19.6" strokeWidth="0.8" />
      </Icon>
    )
  }

  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <rect
        x="9.51922"
        y="0.57"
        width="5.51"
        height="14.06"
        stroke-width="1.14"
      />
      <rect
        x="0.968438"
        y="0.57"
        width="5.51"
        height="14.06"
        stroke-width="1.14"
      />
    </Icon>
  )
}
