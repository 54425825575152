import { ShopifySectionComponent } from '@/types/ShopifySectionComponent'
import { Heading, Icon, Text, Accordion } from '@lounge-fe/ui-kit'
import classNames from 'classnames'

import { AccordionData } from './types'
import { useFeatureFlags } from '@/hooks/useFeatureFlags'

const iconContainerClasses =
  'bg-base-primary text-white w-8 h-8 md:w-10 md:h-10 rounded-circle flex justify-center items-center'

export const TextAccordion: ShopifySectionComponent<AccordionData> = ({
  data,
}) => {
  const { getFlag } = useFeatureFlags()

  const { items, spacer, wrapperSize, title, text, footer } = data! ?? {}

  return (
    <>
      <div
        className={classNames('wrapper text-accordion ', wrapperSize, spacer)}
      >
        {title && (
          <Heading variant="title-four" className="mb-6">
            {title}
          </Heading>
        )}
        {text && (
          <Text
            className="mb-6"
            variant="body-md"
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          ></Text>
        )}
        <Accordion
          closedIcon={<Icon.Plus variant="light" className="w-4 h-4" />}
          openIcon={<Icon.Minus className="w-4 h-4" />}
          divider={true}
          keepMounted={getFlag('keep_accordion_content_mounted')}
        >
          {items.map((item, idx) => {
            const { text, title } = item

            return (
              <Accordion.Item
                key={idx}
                title={
                  <Heading variant="title-six" className="text-left">
                    {title}
                  </Heading>
                }
              >
                <div
                  dangerouslySetInnerHTML={{ __html: text }}
                  className="pb-2"
                />
              </Accordion.Item>
            )
          })}
        </Accordion>
        {footer && (
          <Text
            className="pt-14 mb-6 text-center"
            variant="body-md"
            dangerouslySetInnerHTML={{
              __html: footer,
            }}
          ></Text>
        )}
      </div>
    </>
  )
}

export default TextAccordion
