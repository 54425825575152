import { useSearch } from './useSearch'

import { Text, useMediaQuery } from '@lounge-fe/ui-kit'
import { Navigation } from '../Navigation'
import { Search } from '../Search'
import { ShopifySectionComponent } from '@/types/ShopifySectionComponent'
import { HeaderData } from './types'
import { Basket } from '../Basket'
import { StandardProductCard } from '@/features/product/components/StandardProductCard'
import { useShopify } from '@/hooks/useShopify'
import { useTiles } from './useTiles'
import { t } from 'i18next'
import {
  ClickedProductAfterSearchEvent,
  ConvertedObjectIDsAfterSearchEvent,
  useSearchInsights,
} from '@/hooks/useSearchInsights'
import { useAuth } from '@lounge-fe/auth'
import { usePostHog } from 'posthog-js/react'

export const Header: ShopifySectionComponent<HeaderData> = ({ data }) => {
  const { status: authStatus } = useAuth()
  const posthog = usePostHog()

  const isDesktop = useMediaQuery(
    ({ screens }) => `(min-width: ${screens['2xl']})`,
  )

  const searchTiles = (data?.searchTiles ?? []).filter(({ image }) =>
    Boolean(image),
  )

  const tiles = useTiles({
    tiles: (data?.tiles ?? []).filter(({ image }) => Boolean(image)),
    menuItemsCount: data?.menuItems.length ?? 0,
  })

  const { settings } = useShopify()
  const menuItems = data?.menuItems ?? []
  const emptyBagAction = data?.emptyBagAction

  const {
    onSearchChange,
    isSearchOpen,
    query,
    hits,
    isLastPage,
    showMore,
    nbHits,
    suggestedSearchTerms,
  } = useSearch({
    suggestedSearchTermFallbacks: data?.suggestedSearchTermFallbacks ?? [],
  })

  const { clickedProductAfterSearch, convertedObjectIDsAfterSearch } =
    useSearchInsights()

  return (
    <>
      <Navigation
        hasScrollAnimation={{
          desktop: false,
          mobile: true,
        }}
        basketComponent={
          <Basket
            emptyBagAction={emptyBagAction}
            choiceChipQuickAdd={data?.cartChoiceChipQuickAdd}
            cartDiscountProgress={data?.cartDiscountProgress}
          />
        }
        onSearchSubmit={(value) => onSearchChange(value)}
        searchComponent={
          <Search isFull={query.length > 0} isOpen={isSearchOpen}>
            {query.length > 0 && (
              <>
                <Search.Stats query={query} count={nbHits} />
                <Search.Grid>
                  {/* 
                    TODO: re-enable Wishlist when we have solution for handling multiple popovers
                    Currently, the Wishlist popover closes the Search popover when opened, which causes
                    the wishlist popover to close immediately due to the popover context only allowing
                    one popover to be open at a time
                  */}
                  {hits.map((hit, idx) => (
                    <StandardProductCard
                      product={hit}
                      key={hit.id}
                      useImageCarousel
                      useQuickAdd
                      onClick={() => {
                        if (hit.objectID) {
                          clickedProductAfterSearch({
                            objectID: hit.objectID,
                            eventName: ClickedProductAfterSearchEvent.Search,
                            queryID: hit.queryID,
                            position: idx + 1,
                          })
                          posthog.capture(
                            ClickedProductAfterSearchEvent.Search,
                            {
                              ...hit,
                              search_term: query,
                            },
                          )
                        }
                      }}
                      onQuickAdd={() => {
                        if (hit.queryID && hit.objectID) {
                          convertedObjectIDsAfterSearch({
                            objectID: hit.objectID,
                            eventName:
                              ConvertedObjectIDsAfterSearchEvent.Search,
                            queryID: hit.queryID,
                          })
                          posthog.capture(
                            ConvertedObjectIDsAfterSearchEvent.Search,
                            {
                              ...hit,
                              search_term: query,
                            },
                          )
                        }
                      }}
                      showWishlist={
                        authStatus === 'authenticated' ? true : false
                      }
                    />
                  ))}
                </Search.Grid>
                {!isLastPage && (
                  <Search.LoadMore
                    currentHits={hits.length}
                    totalHits={nbHits}
                    onClick={() => showMore()}
                  />
                )}
              </>
            )}
            {query.length === 0 && (
              <Search.Menu
                tiles={searchTiles.map((tile) => ({
                  href: tile.link,
                  image: tile.image,
                  title: tile.title,
                }))}
              >
                <Search.Recommendations
                  suggestedSearchTerms={suggestedSearchTerms}
                />
              </Search.Menu>
            )}
          </Search>
        }
      >
        {isDesktop ? (
          <Navigation.Desktop>
            {menuItems.map((item: any, index: number) => (
              <Navigation.Item
                title={item.title}
                tiles={tiles.desktop[index] || []}
                key={index}
              >
                {item.sub_links.map((item: any, index: number) => (
                  <Navigation.SubMenu title={item.title} key={index}>
                    {item.sub_links.map((item: any, index: number) => (
                      <Navigation.SubMenuLink
                        href={item.url || '#'}
                        key={index}
                        dangerouslySetInnerHTML={{ __html: item.title }}
                      ></Navigation.SubMenuLink>
                    ))}
                  </Navigation.SubMenu>
                ))}
              </Navigation.Item>
            ))}
          </Navigation.Desktop>
        ) : (
          <Navigation.Mobile mobileCarouselTitle={data?.mobileCarouselTitle}>
            {menuItems.map((item: any, index: number) => (
              <Navigation.Item
                title={item.title}
                tiles={tiles.mobile[index] || []}
                key={index}
              >
                {item.sub_links.map((item: any, index: number) => (
                  <Navigation.SubMenu title={item.title} key={index}>
                    {item.sub_links.map((item: any, index: number) => (
                      <Navigation.SubMenuLink
                        href={item.url || '#'}
                        key={index}
                        dangerouslySetInnerHTML={{ __html: item.title }}
                      ></Navigation.SubMenuLink>
                    ))}
                  </Navigation.SubMenu>
                ))}
              </Navigation.Item>
            ))}
          </Navigation.Mobile>
        )}
      </Navigation>
    </>
  )
}
