import React from 'react'
import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const GridLarge: React.FC<SvgProps> = ({ underline, ...props }) => {
  if (underline) {
    return (
      <Icon viewBox="0 0 16 20" {...props}>
        <rect
          x="0.466667"
          y="0.466667"
          width="15.0667"
          height="15.0667"
          stroke-width="0.933333"
        />
        <line y1="19.6" x2="16" y2="19.6" strokeWidth="0.8" stroke="#11111" />
      </Icon>
    )
  }

  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <rect
        x="0.466667"
        y="0.466667"
        width="15.0667"
        height="15.0667"
        stroke-width="0.933333"
      />
    </Icon>
  )
}
