import classNames from 'classnames'
import { twMerge } from 'tailwind-merge'

import { forwardRef } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import { Popover, usePopoverContext } from '../Popover'
import { Content as PopoverContent } from '../Popover/Content'
import { Portal } from '../Portal'

import { ActionSheetProps } from './types'

export const Content: React.FC<ActionSheetProps> = forwardRef<
  HTMLDivElement,
  ActionSheetProps
>(
  (
    { children, className, direction, dismissDragOffset = 100, ...props },
    ref,
  ) => {
    const { isOpen } = usePopoverContext()

    const motionSettings =
      direction === 'left'
        ? {
            initial: { x: '-100%' },
            animate: { x: '0%' },
            exit: { x: '-100%' },
          }
        : { initial: { x: '100%' }, animate: { x: '0%' }, exit: { x: '100%' } }

    const positionClass = direction === 'left' ? 'left-0' : 'right-0'

    return (
      <Portal>
        <Popover.Backdrop className="z-50" />
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={motionSettings.initial}
              animate={motionSettings.animate}
              exit={motionSettings.exit}
              transition={{
                duration: 0.33,
              }}
              role="complementary"
              className={twMerge(
                classNames(
                  'fixed top-0 bottom-0 z-[60] w-full max-w-[496px] flex justify-center pointer-events-none',
                  positionClass,
                  className,
                ),
              )}
            >
              <PopoverContent
                className={classNames(
                  'flex flex-col bg-white w-full pointer-events-auto relative',
                )}
                ref={ref}
                {...props}
              >
                {children}
              </PopoverContent>
            </motion.div>
          )}
        </AnimatePresence>
      </Portal>
    )
  },
)

Content.displayName = 'ActionSheetContent'
